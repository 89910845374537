<template>
	<mw-dialog
		v-model="dialog"
		small
		submitBtn
		@submit="save"
		cancelBtn
		:title="$t('collaboration.colleague_info')">
		<template v-slot:button>
			<v-btn icon @click="dialog = true">
				<v-icon>mdi-cog</v-icon>
			</v-btn>
		</template>
		<template v-slot:dialog-body>
			<p>{{$t('collaboration.before_edit_colleague_info')}}</p>
            <v-form v-if="user">

            <v-text-field v-model="user.email" :disabled="user.status !== 'pending'"></v-text-field>
            <v-text-field v-model="user.job_title" label="Job Title"></v-text-field>
            <v-text-field v-model="user.department" label="Department"></v-text-field>
            <v-textarea v-model="user.notes" label="Notes" hint="These notes may be visible to the user"></v-textarea>
            </v-form>
		</template>
	</mw-dialog>
</template>
<script>
import MwDialog from "@c/ui/MwDialog";

export default {
	name: "ColleagueDetails",
	props: {
		value: { type: String }, // the ID of the user,
	},
	data: () => {
		return {
			dialog: false,
		};
	},
    computed: {
        user() {
            return this.$store.state.users.data[this.value]
        }
    },
	components: {
		MwDialog,
	},
	methods: {
		save() {
			const self = this;
            self.$store.dispatch("users/patch", self.user ).then(() => {
				self.dialog = false;
			});
		},
	},
};
</script>
